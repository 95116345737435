<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="SECTION_FEE_DETAILS"
      tooltip-title="SECTION_FEE_DETAILS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div v-if="isLoading" class="my-28">
      <Loader :content="true" />
    </div>
    <div v-else class="w-full rounded-lg mt-1">
      <div>
        <div
          class="flex flex-wrap bg-primary-white px-4 pb-5 mt-5 border border-primary-gray-200 rounded-lg"
        >
          <div class="w-full flex mt-5">
            <div class="flex font-roboto font-base w-full">
              <div class="min-w-1.5 mr-2">Fee Title:</div>
              <div class="text-menu capitalize">
                {{ sectionDetails.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </div>
            </div>
          </div>
          <div class="w-full sm:flex-row flex-col sm:gap-0 gap-5 flex mt-5">
            <div class="flex font-roboto font-base sm:w-2/4 w-full">
              <div class="min-w-1.5 mr-2">Fee Amount:</div>
              <div class="text-menu capitalize">
                {{ sectionDetails.fee_amount }}
              </div>
            </div>
            <div class="flex font-roboto font-base sm:w-2/4 w-full">
              <div class="min-w-1.5 mr-2">Currency:</div>
              <div class="text-menu">
                {{ sectionDetails.currency || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </div>
            </div>
          </div>
          <div class="w-full sm:flex-row flex-col sm:gap-0 gap-5 flex mt-5">
            <div class="flex font-roboto font-base sm:w-2/4 w-full">
              <div class="min-w-1.5 mr-2">Fee Type:</div>
              <div class="text-menu">
                {{
                  (sectionDetails.fee_type && sectionDetails.fee_type.title) ||
                  GENERAL_CONSTANTS.NOT_APPLICABLE
                }}
              </div>
            </div>
            <div class="flex font-roboto font-base sm:w-2/4 w-full">
              <div class="min-w-1.5 mr-2">Due Date:</div>
              <div class="text-menu">
                {{ formatDate(sectionDetails.due_date) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </div>
            </div>
          </div>
        </div>
        <!-- Student Fees -->
        <div v-if="!isLoading && isEmpty(sectionStudentsFeeList)">
          <NoRecordFound />
        </div>
        <div v-else class="mt-7">
          <div class="font-medium font-roboto text-xl text-label-text">Student Fee List</div>
          <TableWrapper>
            <THead>
              <TRHead>
                <TH v-for="(head, index) in tableHeaders" :key="index">
                  {{ head }}
                </TH>
              </TRHead>
            </THead>
            <TBody>
              <TRBody v-for="(fee, index) in sectionStudentsFeeList" :key="index">
                <TD>
                  <SingleUserDisplay
                    :required-items="1"
                    :user="fee.student"
                    label="full_name"
                    :image="true"
                    :dropdown-top-bottom="
                      dropdownTopBottom(index, sectionStudentsFeeList, 'bottom-6', 'top-6')
                    "
                    :tooltip="
                      dropdownTopBottom(
                        index,
                        sectionStudentsFeeList,
                        'tooltip-bottom',
                        'tooltip-top',
                      )
                    "
                  />
                </TD>
                <TD>
                  {{ fee.payable_amount }}
                </TD>
                <TD>
                  {{ fee.paid_amount }}
                </TD>
                <TD>
                  {{ fee.discount_amount }}
                </TD>
                <TD>
                  {{ fee.fine_amount }}
                </TD>
                <TD>
                  <PaymentStatus :status="fee.status" />
                </TD>
              </TRBody>
            </TBody>
          </TableWrapper>
        </div>
      </div>
    </div>
    <Pagination
      v-if="sectionFeeCount > 10"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(sectionFeeCount, filteredRecordLimit)"
      @filterRecord="filterRecord"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import PaymentStatus from '@/src/router/views/finance/fees/feeActionsModals/PaymentStatus.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GeneralMixins from '@src/mixins/general-mixins.js'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions } from 'vuex'
import { formatDate } from '@utils/moment.util'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import isEmpty from 'lodash/isEmpty'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import scrollMixin from '@/src/mixins/scroll-mixin'
import { dropdownTopBottom } from '@utils/generalUtil.js'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    TitleWrapper,
    Pagination,
    Loader,
    PaymentStatus,
    SingleUserDisplay,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixins, scrollMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      formatDate,
      filteredRecordLimit: 10,
      sectionDetails: {},
      sectionFeeCount: {},
      sectionStudentsFeeList: [],
      tableHeaders: ['Student Name', 'Payable', 'Paid', 'Discount', 'Fine', 'Status'],
      isLoading: false,
      isEmpty,
    }
  },
  page: {
    title: 'Section Fee Detail',
  },
  computed: {
    sectionFeeId() {
      return this.$route.params?.id
    },
  },
  mounted() {
    this.setRightBar()
    this.filterRecord()
  },
  methods: {
    dropdownTopBottom,
    filterRecord(range) {
      this.isLoading = true
      let payload = {
        skip: range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || GENERAL_CONSTANTS.RECORD_LIMIT,
        section_fee_id: this.sectionFeeId,
      }
      this.getSectionFeeDetails(payload).then((res) => {
        this.sectionFeeCount = res?.data?.meta.total_records
        this.sectionDetails = res?.data?.section_fee || {}
        this.sectionStudentsFeeList = res?.data?.records
        this.sectionStudentsFeeList.forEach((fee) => {
          fullName(fee.student)
        })
        this.isLoading = false
      })
    },
    setRightBar() {
      const rightBarContent = {
        header: {
          title: 'SECTION_FEE_DETAILS',
        },
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('fee', ['getSectionFeeDetails']),
  },
}
</script>
